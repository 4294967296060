<template>
    <base-page>
        <titlu-pagina Titlu="Raport financiar" :AdaugaVisible='false'  />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">

                        <el-col :span='4'>
                            <el-form-item label='Utilizator' >
                                <el-select class='full-width' v-model='Filters.IdUtilizator' filterable >
                                    <el-option label='Toti' value='-1'></el-option>
                                    <el-option v-for='item in Info.angajati' :key="'angajati' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                                    
                        <el-col :span='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' > Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column type="expand" >
                <template slot-scope="props">
                    <el-table :data="props.row.Proiecte" border>
                        <el-table-column type="expand" >
                            <template slot-scope="props">
                                <el-table :data="props.row.Activitati" border>
                                    <el-table-column prop="Nume" label="Activitate"></el-table-column>
                                    <el-table-column prop="NumarOre" label="Numar ore"></el-table-column>
                                    <el-table-column prop="Spor" label="Spor (%)"></el-table-column>
                                    <el-table-column prop="ValoareSpor" label="Valoare spor (lei)"></el-table-column>
                                </el-table>
                            </template>                            
                        </el-table-column>
                        <el-table-column prop="Nume" label="Proiect"></el-table-column>
                        <el-table-column prop="NumarOre" label="Numar ore"></el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column label='Nume'>
                <template slot-scope="scope">
                     <div>{{scope.row.Nume }}</div>
                </template>
            </el-table-column>
            <el-table-column prop='SalariuDeBazaCalculOre' label='Salariu de baza'></el-table-column>
            <el-table-column prop='Spor' label='Spor (%)'></el-table-column>
            <el-table-column prop="ValoareSpor" label="Valoare spor (lei)"></el-table-column>
            
        </el-table>
       
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <!-- <Utilizatori-dialog ref='dlg' @save="refresh_info()" /> -->
    </base-page>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from "@/pages/BasePage";
    import TitluPagina from '@/widgets/TitluPagina';

    export default {
        name: "raport_financiar",
        extends: BasePage,
        components: {
            'base-page': BasePage,
            'titlu-pagina': TitluPagina
        },
        data () {
            return {
                Results: [],
                base_url: '',
                Info: { angajati:[] },
                Filters: { IdUtilizator: '-1' },                    
                OrderBy: { },
                PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
                
                
            }
        },
        methods: {

            async get_info(){
                var response        = await this.post("raport_financiar/get_info" );
                this.Info.angajati  = response.Angajati                
                this.refresh_info();
            },

            async refresh_info(){
                var response        = await this.post("raport_financiar/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
                this.Results        = response.Results;
                this.PaginationInfo = response.PaginationInfo;
                //
                // this.select_menu_item('utilizatori');
            },
            reset(){
                this.Filters = { IdUtilizator: '-1' }
                this.refresh_info();
            },


            // show_dialog(id){
            //     this.$refs.dlg.show_me(id);
            // }
        },
        mounted(){
            this.base_url = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
